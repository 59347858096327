import React from 'react';
import { graphql } from 'gatsby';
import { get } from 'lodash';

import '../styles/fonts.css';

import Hero from '../components/Hero';
import Layout from '../components/Layout';
import ContentBlocks from '../components/ContentBlocks';

export const query = graphql`
  query PageQuery($slug: String) {
    siteSetting: sanitySiteSettings {
      siteUrl
    }
    page: sanityPage(slug: { current: { eq: $slug } }) {
      seoAuthor {
        id
        twitter
      }
      slug {
        current
      }
      seoTitle
      seoDescription
      seoKeywords
      seoImage {
        alt
        asset {
          fixed(width: 1200) {
            ...GatsbySanityImageFixed_noBase64
          }
        }
      }
      _rawHero(resolveReferences: { maxDepth: 5 })
      _rawContentBlocks(resolveReferences: { maxDepth: 10 })
    }
  }
`;

const Page = ({ data }) => {
  const {
    siteSetting,
    page,
    page: { _rawContentBlocks: pageBlocks, _rawHero: hero },
  } = data;

  const url = `${siteSetting.siteUrl}/${get(page, 'slug.current', '')}`;

  const seo = {
    title: get(page, 'seoTitle', ''),
    seoDescription: get(page, 'seoDescription', ''),
    seoKeywords: get(page, 'seoKeywords', ''),
    seoTwitterHandle: get(page, 'author.twitter', '@thejibe'),
    image: get(page, 'seoImage.asset.fixed.src', ''),
    url,
  };

  return (
    <Layout seo={seo}>
      <Hero hero={hero} />
      <ContentBlocks pageBlocks={pageBlocks} />
    </Layout>
  );
};

export default Page;
