import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import BlockContent from '@sanity/block-content-to-react';

import { Container } from '../elements';
import { above, below } from '../styles';
import checkPropsEquality from '../utils/checkPropsEquality';

// styles if hero block are aligned to the right
const alignRightStyles = css`
  align-items: flex-end;
`;

const StyledBlockContent = styled.div`
  ${props => props.BlockContent} {
    ${props => (props.renderHeadingFirst ? '' : 'order: -1')}
  }
  a {
    color: ${props => props.theme.color.green};
    background-image: linear-gradient(
      ${props => props.theme.color.green},
      ${props => props.theme.color.green}
    );
    background-position: 0% 87%;
    background-repeat: no-repeat;
    background-size: 0% 5px;
    transition: background-size 0.3s;

    &:hover {
      background-size: 100% 5px;
    }
  }
`;

const HeroWrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-bottom: 50px;
  ${props => props.heroBlockAlign === 'right' && alignRightStyles};

  @media ${below.md} {
    margin-bottom: 50px;
    margin-top: 100px;
  }

  @media ${above.lg} {
    margin-top: ${props => (props.extraMarginTop ? '160px' : '135px')};
    margin-bottom: 40px;
  }
`;

const HeadingBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const offsetHeadingStyles = css`
  margin-left: 70px;

  @media ${above.lg} {
    margin-left: 150px;
    max-width: 770px;
  }
`;

const offsetSubheadingStyles = css`
  margin-left: 70px;
  @media ${above.sm} {
    max-width: 400px;
  }

  @media ${above.lg} {
    margin-left: 150px;
    max-width: 620px;
  }
`;

const narrowHeadingStyles = css`
  @media ${above.lg} {
    max-width: 500px;
  }
`;

const mediumHeadingStyles = css`
  @media ${above.lg} {
    max-width: 770px;
  }
`;

const largeHeadingStyles = css`
  @media ${above.lg} {
    max-width: 900px;
  }
`;

const Heading = styled.h1`
  font-weight: bold;
  font-size: 4.6rem;
  line-height: 1.25;
  margin-bottom: 20px !important;
  color: ${props => props.theme.color.blue.navy};

  @media ${above.lg} {
    font-size: ${props => (props.largeHeading ? '9rem' : '7.5rem')};
  }
  ${props => props.offsetHeading && offsetHeadingStyles};
  ${props => props.headingWidth === 'narrow' && narrowHeadingStyles};
  ${props => props.headingWidth === 'medium' && mediumHeadingStyles};
  ${props => props.headingWidth === 'large' && largeHeadingStyles};
`;

const Subheading = styled.h2`
  font-size: 2.4rem;
  line-height: 1.8;
  font-weight: normal;

  @media ${above.sm} {
    max-width: 750px;
  }

  @media ${above.lg} {
    font-size: 3.6rem;
    font-size: ${props => (props.smallSubHeading ? '1.8rem' : '3.6rem')};
    max-width: ${props =>
      props.subheadingWidth === 'medium' ? '650px' : '900px'};
  }

  @media ${below.md} {
    font-size: ${props => (props.smallSubHeading ? '1.8rem' : '2.4rem')};
  }

  ${props => props.offsetSubheading && offsetSubheadingStyles};
`;

const StyledLink = styled(Link)`
  &:visited {
    color: ${props => props.theme.color.green};
  }
`;

const BackgroundWrapper = styled.div`
  overflow: auto;
  ${props => (props.noHero ? 'height: 0' : '')}
  ${props =>
    props.backgroundColor && `background-color: ${props.backgroundColor}`};
`;

const Hero = ({ hero }) => {
  if (!hero) return null;

  const {
    headingWidth,
    subheadingWidth,
    heroTitle,
    heroSubtitle,
    heroBlockAlign,
    heroOffset,
    heroTitlePosition,
    backgroundColor,
    largeHeading,
    headingMarginBottom,
    smallSubHeading,
    extraMarginTop,
    noHero,
  } = hero;
  const renderHeadingFirst = heroTitlePosition === 'top';

  const offsetSubheading = renderHeadingFirst && heroOffset;
  const offsetHeading = !renderHeadingFirst && heroOffset;
  var markDefs = heroSubtitle ? heroSubtitle[0].markDefs : null;

  // helper function to map markDefs
  const getMatchingMarkDefsFromMarksKey = key =>
    markDefs.find(m => m._key === key);

  const InternalLink = props => {
    const { children, markKey } = props;
    var category;

    var markDefsRef = getMatchingMarkDefsFromMarksKey(markKey);

    if (markDefsRef && markDefsRef.reference._type === 'page') {
      category = '';
    } else {
      category = markDefsRef.reference._rawCategories.slug.current;
    }
    var slug = markDefsRef.reference.slug.current;
    var path = `${category}/${slug}`;

    return (
      <StyledLink key={markKey} to={path}>
        {children.map(c => c)}
      </StyledLink>
    );
  };

  const ExternalLink = props => {
    const {
      mark: { href },
      children,
    } = props;
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children[0]}
      </a>
    );
  };

  const SubHeading = props => {
    const { children } = props;

    return (
      <Subheading
        offsetSubheading={offsetSubheading}
        smallSubHeading={smallSubHeading}
        subheadingWidth={subheadingWidth}
      >
        {children}
      </Subheading>
    );
  };

  return (
    <BackgroundWrapper
      backgroundColor={backgroundColor && backgroundColor.hex}
      noHero={noHero}
    >
      <Container>
        <HeroWrapper
          heroBlockAlign={heroBlockAlign}
          heroOffset={heroOffset}
          extraMarginTop={extraMarginTop}
        >
          <HeadingBlock>
            <Heading
              headingMarginBottom={headingMarginBottom + 'px'}
              offsetHeading={offsetHeading}
              headingWidth={headingWidth}
              largeHeading={largeHeading}
            >
              {heroTitle}
            </Heading>
            <StyledBlockContent renderHeadingFirst={renderHeadingFirst}>
              <BlockContent
                blocks={heroSubtitle}
                serializers={{
                  marks: {
                    internalLink: InternalLink,
                    externalLink: ExternalLink,
                  },
                  types: {
                    block: SubHeading,
                  },
                }}
              />
            </StyledBlockContent>
          </HeadingBlock>
        </HeroWrapper>
      </Container>
    </BackgroundWrapper>
  );
};

export default React.memo(Hero, checkPropsEquality);
